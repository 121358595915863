<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <div class="page-title text-center px-4">
        <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
          <span class="me-2">{{ $t('page.not_found.title') }}</span>
          <v-icon color="warning">
            {{ icons.mdiAlert }}
          </v-icon>
        </h2>
        <p class="text-sm">
          {{ $t('page.not_found.description') }}
        </p>

        <v-btn color="primary" block outlined @click="$router.push('/')">
          {{ $t('common.back') }}
          <v-icon class="ml-1" small>{{ icons.mdiArrowULeftTop }}</v-icon>
        </v-btn>
      </div>
    </div>
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import { mdiAlert, mdiArrowULeftTop } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
        mdiArrowULeftTop,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
